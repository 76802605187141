import { $ } from './../helpers/qsa'
import smoothScroll from 'smoothscroll-polyfill'

// Polyfill smooth scroll behaviour
smoothScroll.polyfill()

const masthead = $('.masthead')

// Do we have a scroll link?
const scrollLink = $('.masthead__arrow')

if ( scrollLink ) {
    const scrollTarget = masthead.nextElementSibling

    scrollLink.addEventListener('click', e => {
        e.preventDefault()
        scrollTarget.scrollIntoView({ behavior: 'smooth' })
    })
}

// Fix masthead overflowing viewport on mobile
const fixMasthead = () => {
    const { borderWidth } = getComputedStyle(document.documentElement)

    masthead.style.minHeight = '100vh'

    if ( masthead.clientHeight + (parseInt(borderWidth) * 2) > window.innerHeight ) {
        masthead.style.minHeight = `${window.innerHeight - (parseInt(borderWidth) * 2)}px`
    }
}

window.addEventListener('orientationchange', fixMasthead, false)

fixMasthead()
